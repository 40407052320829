<template>
  <b-container fluid>
      <form >
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('company.company_background_image') }}</h4>
              </template>
              <template v-slot:body>
                <div class="add-img-user">
                  <b-img class="height-150" fluid :src="user.background_image" alt="profile-pic" />
                  <input type="hidden" v-model="user.background_image">
                  <div class="p-image">
                    <b-button variant="none" class="upload-button iq-bg-primary position-relative">
                      <input type="file" ref="file1" @change="previewImage1(user)" class="h-100 position-absolute" accept="image/*" style="opacity: 0;" />
                      {{ $t('company.change_background_image') }}
                    </b-button>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="3">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('company.logo') }}</h4>
              </template>
              <template v-slot:body>
                <b-form-group>
                  <div class="add-img-user profile-img-edit">
                    <b-img class="profile-pic height-150 width-150" fluid :src="user.logo" alt="profile-pic" />
                    <input type="hidden" v-model="user.logo">
                    <div class="p-image">
                      <b-button variant="none" class="upload-button iq-bg-primary position-relative">
                        <input type="file" ref="file" @change="previewImage(user)" class="h-100 position-absolute" accept="image/*" style="opacity: 0;" />
                        {{ $t('company.change_logo') }}
                      </b-button>
                    </div>
                  </div>
                  <div class="img-extension mt-3">
                    <div class="d-inline-block align-items-center">
                      <span>{{ $t('company.only') }}</span>
                      <b-link href="javascript:void();">.jpg</b-link>
                      <b-link href="javascript:void();">.png</b-link>
                      <b-link href="javascript:void();">.jpeg</b-link>
                      <span>{{ $t('company.allowed') }}</span>
                    </div>
                  </div>
                </b-form-group>

              </template>
            </iq-card>
          </b-col>
          <b-col lg="9">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('company.company_details') }}</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-12"
                                  label="Company Name:"
                                  label-for="cname"
                    >
                      <b-form-input v-model="user.name" type="text" name="cname" id="cname" :placeholder="$t('company.company_name')"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-sm-6"
                                  label-for="selectcountry"
                                  :label="$t('company.country')">
                      <b-form-select v-model="user.country" :options="countries" id="selectcountry">
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{ $t('company.select_country') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group class="col-md-6"
                                  :label="$t('company.city')"
                                  label-for="city"
                    >
                      <b-form-input  v-model="user.city" type="text" name="city" id="city" :placeholder="$t('company.city')"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-6"
                                  :label="$t('company.street_address')"
                                  label-for="add1"
                    >
                      <b-form-input v-model="user.address" type="text" name="add1" id="add1" :placeholder="$t('company.street_address')"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-sm-6"
                                  label-for="selectlanguage"
                                  :label="$t('company.language')">
                      <b-form-select v-model="user.language" :options="languages" id="selectlanguage">
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{ $t('company.select_language') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group class="col-sm-12" :label="$t('customer_.visibility')">
                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                        <div class="custom-switch-inner">
                          <input type="checkbox" class="custom-control-input bg-primary" id="visibility" v-model="user.visibility">
                          <label class="custom-control-label" for="visibility" :data-on-label="$t('payment.yes')" :data-off-label="$t('payment.no')">
                          </label>
                        </div>
                      </div>
                    </b-form-group>

                    <b-form-group class="col-md-6"
                                  :label="$t('customer_.latitude')"
                                  label-for="latitude"
                    >
                      <b-form-input  v-model="user.latitude" type="text" name="latitude" id="latitude" :placeholder="$t('customer_.latitude')"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-6"
                                  :label="$t('customer_.longitude')"
                                  label-for="longitude"
                    >
                      <b-form-input v-model="user.longitude" type="text" name="longitude" id="longitude" :placeholder="$t('customer_.longitude')"></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-12">
                      {{ $t('customer_.lat_and_long') }}
                    </b-form-group>

                  </b-row>
                  <hr>
                  <h5 class="mb-3">{{ $t('company.contact') }}</h5>
                  <b-row>
                    <b-form-group class="col-md-6" :label="$t('company.phone_number')" label-for="mobno">
                      <b-form-input v-model="user.phone" type="text" :placeholder="$t('company.phone_number')"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-6" :label="$t('company.website')" label-for="website">
                      <b-form-input v-model="user.website" type="text" :placeholder="$t('company.website')"></b-form-input>
                    </b-form-group>
                  </b-row>
                  <hr>
                  <h5 class="mb-3">{{ $t('company.social_network') }}</h5>
                  <b-row>
                    <b-form-group class="col-md-6"
                      :label="$t('company.facebook_url')"
                      label-for="furl"
                    >
                      <b-form-input v-model="user.facebook_url" id="furl" name="furl" :placeholder="$t('company.facebook_url')"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-6"
                      :label="$t('company.twitter_url')"
                      label-for="turl"
                    >
                      <b-form-input v-model="user.twitter_url" id="turl" name="turl" :placeholder="$t('company.twitter_url')"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-6"
                      :label="$t('company.instagram_url')"
                      label-for="turl"
                    >
                      <b-form-input v-model="user.instagram_url" id="instaurl" name="instaurl" :placeholder="$t('company.instagram_url')"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-6"
                      :label="$t('company.linkedin_url')"
                      label-for="lurl"
                    >
                      <b-form-input v-model="user.linkedin_url" id="lurl" :placeholder="$t('company.linkedin_url')"></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-button variant="primary" type="button" @click="onSubmit(user)">
                    {{ $t('company.save_company_details') }}
                  </b-button>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'Settings',
  mounted () {
    core.index()
    this.getCompanyDetails()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      user: {
        address: '',
        name: '',
        background_image: require('../../assets/images/bg-03.jpg'),
        logo: require('../../assets/images/splash.png'),
        phone: '',
        website: '',
        country: '',
        language: '',
        city: '',
        visibility: true,
        latitude: '',
        longitude: '',
        instagram_url: '',
        facebook_url: '',
        twitter_url: '',
        linkedin_url: ''
      },
      countries: [
        { value: 'USA', text: 'USA' },
        { value: 'UK', text: 'UK' },
        { value: 'Ireland', text: 'Ireland' },
        { value: 'North Ireland', text: 'North Ireland' },
        { value: 'Romania', text: 'Romania' },
        { value: 'Canada', text: 'Canada' },
        { value: 'France', text: 'France' },
        { value: 'Germany', text: 'Germany' },
        { value: 'Italy', text: 'Italy' },
        { value: 'Spain', text: 'Spain' }
      ],
      languages: [
        { value: 'en', text: 'English' },
        { value: 'de', text: 'German' },
        { value: 'fr', text: 'French' },
        { value: 'it', text: 'Italian' },
        { value: 'es', text: 'Spanish' },
        { value: 'ro', text: 'Romanian' }
      ]
    }
  },
  methods: {
    getCompanyDetails () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_COMPANY_DETAILS)
        .then((response) => {
          if (response.data.status === 'success') {
            this.user.name = response.data.company.name
            if (response.data.company.logo) {
              this.user.logo = response.data.company.logo
            }
            this.user.address = response.data.company.address
            if (response.data.company.background_image) {
              this.user.background_image = response.data.company.background_image
            }
            this.user.country = response.data.company.country
            this.user.language = response.data.company.language
            this.user.city = response.data.company.city
            this.user.visibility = response.data.company.visibility === '1'
            this.user.latitude = response.data.company.latitude
            this.user.longitude = response.data.company.longitude
            this.user.website = response.data.company.website
            this.user.phone = response.data.company.phone
            const social = JSON.parse(response.data.company.social)
            this.user.facebook_url = social.facebook || ''
            this.user.twitter_url = social.twitter || ''
            this.user.instagram_url = social.instagram || ''
            this.user.linkedin_url = social.linkedin || ''
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    onSubmit (item) {
      const formData = new FormData()
      if (this.$refs.file.files[0]) {
        formData.append('logo', this.$refs.file.files[0])
      }
      if (this.$refs.file1.files[0]) {
        formData.append('background_image', this.$refs.file1.files[0])
      }
      formData.append('name', item.name)
      formData.append('country', item.country)
      formData.append('city', item.city)
      formData.append('latitude', item.latitude)
      formData.append('longitude', item.longitude)
      formData.append('visibility', item.visibility)
      formData.append('language', item.language)
      formData.append('phone', item.phone)
      formData.append('website', item.website)
      formData.append('address', item.address)
      const social = {
        facebook: item.facebook_url,
        twitter: item.twitter_url,
        instagram: item.instagram_url,
        linkedin: item.linkedin_url
      }
      formData.append('social', JSON.stringify(social))

      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_COMPANY_ADD_DETAILS, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('company.company_details_updated'))
          } else if (response.data.status === 'upgrade') {
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    previewImage: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.logo = e.target.result
      }
      reader.readAsDataURL(this.$refs.file.files[0])
    },
    previewImage1: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.background_image = e.target.result
      }
      reader.readAsDataURL(this.$refs.file1.files[0])
    }
  }
}
</script>
